module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://59504855f18d4201957c4dc741515e21@o528007.ingest.sentry.io/5645771","environment":"prod","release":"73948c1475cccd6db66b70b2701d851c95788d65","debug":false,"sampleRate":1,"tracesSampleRate":0.5,"sendDefaultPii":true,"autoSessionTracking":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Covidseeker","start_url":"/","background_color":"#052049","theme_color":"#052049","display":"browser","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"770dd9fed86b7c643674636a35a6bace"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://covidseeker.ucsf.edu"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://covidseeker.ucsf.edu/","path":"/Users/nicholas/projects/ucsf-covid19-website/src/locales","languages":["en","es"],"defaultLanguage":"en","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"react":{"transSupportBasicHtmlNodes":true,"transKeepBasicHtmlNodesFor":["br","strong","em","p","b","i","small"]}}},
    }]
