// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-amt-dashboard-jsx": () => import("./../../../src/pages/amt-dashboard.jsx" /* webpackChunkName: "component---src-pages-amt-dashboard-jsx" */),
  "component---src-pages-amt-jsx": () => import("./../../../src/pages/amt.jsx" /* webpackChunkName: "component---src-pages-amt-jsx" */),
  "component---src-pages-citizen-dashboard-jsx": () => import("./../../../src/pages/citizen-dashboard.jsx" /* webpackChunkName: "component---src-pages-citizen-dashboard-jsx" */),
  "component---src-pages-citizen-jsx": () => import("./../../../src/pages/citizen.jsx" /* webpackChunkName: "component---src-pages-citizen-jsx" */),
  "component---src-pages-dashboard-jsx": () => import("./../../../src/pages/dashboard.jsx" /* webpackChunkName: "component---src-pages-dashboard-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-map-jsx": () => import("./../../../src/pages/map.jsx" /* webpackChunkName: "component---src-pages-map-jsx" */),
  "component---src-pages-upload-jsx": () => import("./../../../src/pages/upload.jsx" /* webpackChunkName: "component---src-pages-upload-jsx" */)
}

